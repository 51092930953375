<template>
  <div id="productDiscountContainer">
    <form id="productDiscountForm">
      <CRow id="productDiscountRow">
        <CCol sm="12" lg="12" md="12" id="productDiscountCol">
          <div class="overflow-auto" style="max-height: 500px" id="productDiscountOverflow">
            <table
              id="productDiscountTable"
              style="white-space: nowrap"
              class="table table-responsive table-striped table-bordered"
            >
              <thead id="productDiscountTableHead">
                <tr>
                  <th id="productDiscountQuantityPriceHeader" class="text-dark font-weight-normal" style="width: 10%">
                    {{ $t("quantityPrice") }}
                  </th>
                  <th id="productDiscountCostHeader" class="text-dark font-weight-normal" style="width: 10%">
                    {{ $t("cost") }}
                  </th>
                  <th id="productDiscountExceedUcHeader" class="text-dark font-weight-normal" style="width: 10%">
                    {{ $t("exceedUc") }}
                  </th>
                  <th id="productDiscountPriceUnitHeader" class="text-dark font-weight-normal" style="width: 10%">
                    {{ $t("priceUnit") }}
                  </th>
                  <th id="productDiscountActionHeader" class="text-dark font-weight-normal" style="width: 2%"></th>
                </tr>
              </thead>
              <tbody v-if="loading == true" id="productDiscountTableBody">
                <tr
                  v-for="(item, index) in rowslist"
                  :key="index"
                  id="'productDiscountRow' + index"
                >
                  <td :id="'productDiscountPackQty' + index" style="width: 10%">
                    <input
                      type="number"
                      :value="isNaN(item.packQty) ? 0 : item.packQty"
                      disabled
                      class="form-control text-right"
                    />
                  </td>
                  <td :id="'productDiscountStdCost' + index" style="width: 10%">
                    <div v-if="index == 0">
                      <input
                        min="0"
                        v-model.number="item.stdCost"
                        type="number"
                        :class="[
                          'form-control',
                          'text-right',
                          {
                            'text-danger':
                              item.stdCost > item.price &&
                              item.price > 0,
                          },
                        ]"
                        disabled
                      />
                      <div :id="'productDiscountStdCostContainer'+ index" class="d-flex justify-content-end">
                          <span
                            :id="'productDiscountStdCostWarningPrimary'+ index"
                            v-if="item.stdCost > item.price && item.price > 0"
                            class="text-danger text-right"
                            style="font-size: 10px"
                          >
                            * {{ $t("stdCostWarning") }}
                          </span>
                        </div>
                        </div>
                        <div v-else>
                          <input
                            :id="'productDiscountStdCostInput'+ index"
                            v-model.number="item.stdCost"
                            :class="[
                              'form-control',
                              'text-right',
                              {
                                'text-danger': item.stdCost > item.price && item.stdCost > 0,
                              },
                            ]"
                            disabled
                          />
                          <div
                            :id="'productDiscountStdCostContainerSecondary'+ index"
                            class="d-flex justify-content-end"
                          >
                            <span
                              :id="'productDiscountStdCostWarningSecondary'+ index"
                              v-if="item.stdCost > item.price && item.stdCost > 0"
                              class="text-danger text-right"
                              style="font-size: 10px"
                            >
                              * {{ $t("stdCostWarning") }}
                            </span>
                          </div>
                        </div>
                        </td>
                  <td :id="'productDiscountPackUc' + index" style="width: 10%">
                    <input v-model.number="item.packUc" disabled class="form-control text-right" />
                  </td>
                  <td :id="'productDiscountExceedUc' + index" style="width: 10%">
                    <input v-if="index == 0" v-model="item.exceedUc" disabled style="background-color: #d8dbe0"
                      class="form-control text-right" />
                  </td>
                  <td :id="'productDiscountActions' + index" class="text-center" style="width: 3%">
                    <CRow>
                      <CCol sm="6" md="6" :id="'productDiscountEditCol' + index" style="width: 3%">
                        <img
                          :id="'productDiscountEdit' + index"
                          src="/img/file-edit.png"
                          @click="EditPRU(item)"
                          v-show="isEditData"
                          style="
                            width: 14px;
                            height: 14px;
                            margin-right: 8px;
                            cursor: pointer;
                          "
                        />
                      </CCol>
                      <CCol sm="6" md="6" :id="'productDiscountDeleteCol' + index" style="width: 3%">
                        <img
                          :id="'productDiscountDelete' + index"
                          src="/img/trash.png"
                          style="
                            width: 14px;
                            height: 14px;
                            cursor: pointer;
                          "
                          @click="deletePRU(item.objectId)"
                          v-show="isEditData"
                        />
                      </CCol>
                    </CRow>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
      <CRow id="productDiscountModalRow">
        <CModal
          id="productDiscountProductRRUModal"
          :show.sync="productPRU"
          :title="namemodal"
          centered
        >
          <small
            id="productDiscountProductRRUAlert"
            class="text-danger font-weight-normal"
            v-if="alertValidatePRU !== ''"
          >
            {{ alertValidatePRU }}
          </small>
          <CRow id="productDiscountProductRRURow">
            <CCol id="productDiscountProductRRUQuantityCol" md="3">
              <span id="productDiscountProductRRUQuantityLabel">{{ $t('quantityPrice') }}</span>
            </CCol>
            <CCol id="productDiscountProductRRUQuantityInputCol" md="9">
              <CInput
                id="productDiscountProductRRUQuantityInput"
                v-model.number="pru.packQty"
                type="number"
                min="1"
                :placeholder="$t('quantityPrice')"
              />
            </CCol>
            <CCol id="productDiscountProductRRUCostCol" md="3">
              <span id="productDiscountProductRRUCostLabel">{{ $t('cost') }}</span>
            </CCol>
            <CCol id="productDiscountProductRRUCostInputCol" md="9">
              <CInput
                id="productDiscountProductRRUCostInput"
                :placeholder="$t('cost')"
                type="number"
                v-model="pru.stdCost"
                :value="isNaN((pru.stdCost = pru.packQty * productSKUdetail.stdCost))
                  ? 0
                  : (pru.stdCost = pru.packQty * productSKUdetail.stdCost)"
                disabled
              />
            </CCol>
            <CCol id="productDiscountProductRRUExceedUcCol" md="3">
              <span id="productDiscountProductRRUExceedUcLabel">{{ $t('exceedUc') }}</span>
            </CCol>
            <CCol id="productDiscountProductRRUExceedUcInputCol" md="9">
              <CInput
                id="productDiscountProductRRUExceedUcInput"
                v-model.number="pru.packUc"
                type="number"
                min="0"
                :placeholder="$t('exceedUc')"
                :value="isNaN(packUctotal) ? 0 : packUctotal"
              />
            </CCol>
            <CCol id="productDiscountProductRRUPriceUnitCol" md="3">
              <span id="productDiscountProductRRUPriceUnitLabel">{{ $t('priceUnit') }}</span>
            </CCol>
            <CCol id="productDiscountProductRRUPriceUnitInputCol" md="9">
              <input
                id="productDiscountProductRRUPriceUnitInput"
                v-model.number="exceedUCtotal"
                @input="checkExceed($event)"
                type="number"
                min="0"
                :placeholder="$t('priceUnit')"
                class="form-control"
                style="background-color: #d8dbe0"
              />
            </CCol>
          </CRow>

          <template #footer-wrapper>
            <CRow id="productDiscountProductRRUFooter" class="p-4">
              <CCol id="productDiscountProductRRUFooterSaveCol">
                <CButton
                  id="productDiscountProductRRUSaveButton"
                  v-if="loadingButtonValidatePRU === false"
                  color="success"
                  v-on:click="validatePRU"
                  block
                >
                  {{ $t('save') }}
                </CButton>
                <CButton
                  id="productDiscountProductRRUSaveButtonDisabled"
                  v-else
                  color="success"
                  block
                  disabled
                >
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol id="productDiscountProductRRUFooterCancelCol">
                <CButton
                  id="productDiscountProductRRUCancelButton"
                  v-if="loadingButtonValidatePRU === false"
                  color="light"
                  @click="productPRU = false"
                  block
                >
                  {{ $t('cancel') }}
                </CButton>
                <CButton
                  id="productDiscountProductRRUCancelButtonDisabled"
                  v-else
                  color="light"
                  block
                  disabled
                >
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>

      </CRow>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'


export default {
  components: {},
  data() {
    return {
      countSKU: 0,
      rows: [],
      loading: true,
      itemid: '',
      pru: {
        exceedUc: 0,
        packQty: 0,
        packUc: 0,
        stdCost: 0,
      },
      alertValidatePRU: '',
      itemObjectId: '',
      namemodal: '',
      productPRU: false,
      loadingButtonValidatePRU: false,
      
    
    };
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    exceedUCtotal: {
      get() {
        let data = this.pru.packUc / this.pru.packQty
        return data
      },
      set() {
        let data = this.pru.packUc / this.pru.packQty
        return data
      },
    },
    packUctotal() {
      let data = this.pru.packUc * this.pru.packQty
      return data
    },
    productSKUdetail:{
      get(){
        return  this.$store.getters.productSKUdetail
      },
      set(){
        
        return this.$store.getters.productSKUdetail
      }
    },
    isEditData() {
      let path = '/product'
      const value = permis.findPermissionEdit('product', path)
      return value //&& this.editItem
    },
    rowslist() {

      let data = this.rows
      let detail = []
      let cost = parseFloat(this.productSKUdetail.stdCost)
      
      let stdCost = typeof cost == 'number' ? parseFloat(cost) : 0
      if (data.length > 0) {
        data.forEach((e) => {
          detail.push({
            id: e.id,
            objectId: e.objectId,
            packQty: e.packQty,
            price: (e.packUc),
            packUc: util.convertCurrency(e.packUc),
            exceedUc: util.convertCurrency(e.exceedUc),
            stdCost: typeof stdCost == 'number' ? (stdCost * e.packQty).toFixed(2) : stdCost,
            skuType: e.skuType
          })
        })
      } else {
        detail.push({
          id: '',
          objectId: this.productSKUObjectId,
          packQty: 1,
          exceedUc: util.convertCurrency(this.productSKUdetail.SKUPrice),
          stdCost: typeof stdCost == 'number' ? (stdCost).toFixed(2) : stdCost,
          price: this.productSKUdetail.SKUPrice,
          packUc: this.productSKUdetail.SKUPrice,
          skuType: this.productSKUdetail.skuType
        })
      }

      
      return detail
    },
  },
  async created() {
    await this.getProductPRU()

  },
  mounted() { },
  methods: {
    checkExceed(event) {
      this.pru.packUc = event.target.value * this.pru.packQty
    },
    addproductPRU() {
      this.itemid = ''
      this.itemObjectId = ''
      this.pru.packQty = 1
      this.pru.packUc = 0
      this.pru.exceedUc = 0
      // this.pru.stdCost = this.productSKUdetail.stdCost
      this.namemodal = this.$i18n.t('addPrice')
      this.productPRU = true
    },
    EditPRU(item) {
      this.alertValidatePRU = ''
      this.itemid = item.id
      this.itemObjectId = item.objectId
      this.pru.packQty = item.packQty
      this.pru.packUc = util.currencyStringToNumber(item.packUc)
      this.namemodal = this.$i18n.t('editPrice')
      this.productPRU = true
    },
    deletePRU(item) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      this.loading = false
      let data = {
        shopObjectId: shopObjectId,
        objectId: item,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductPRU/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.loading = true
            this.getProductPRU()
          }, 100)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    async getProductPRU() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const skuObjectId = this.$route.params.productSKUObjectId;
      let params = { skuObjectId: skuObjectId }

      this.loading = false

      try {
        const res = await local.get('/api/v1.0/' + uid + '/productpru/getbysku', { params })
        this.rows = res.data.data
        this.countSKU = this.rows.length
        const skuType = this.skuType
        this.rows.forEach(row => {
          row.skuType = skuType
        })
        this.loading = true
      } catch (error) {
        console.log(error)
      }
    },
    saveProductpru() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      let data = {
        enabled: true,
        exceedUc: this.pru.packUc / this.pru.packQty,
        packQty: this.pru.packQty,
        packUc: this.pru.packUc,
        productSKU: {
          SKUName: this.productSKUdetail.SKUName,
          SKUPrice: this.productSKUdetail.SKUPrice,
          id: this.productSKUdetail.id,
          objectId: this.productSKUdetail.objectId,
        },
        productSKUId: this.productSKUdetail.id,
        shop: {
          objectId: this.shop.objectId,
          id: this.shop.id,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        stdCost: this.productSKUdetail.stdCost * this.pru.packQty, //ต้นทุน
        negotiatePrice: this.productSKUdetail.negotiatePrice,
      }
      if (this.itemObjectId !== '') {
        data['id'] = this.itemid
        data['objectId'] = this.itemObjectId
      }

      const headers = { shopObjectId: this.shopObjectId }

      this.loading = false
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productpru/add',
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            this.productPRU = false
            this.getProductPRU()
            this.loading = true
            this.loadingButtonValidatePRU = false
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    validatePRU() {
      let newPRU = this.rowslist.filter((item) => {
        if (
          item.packUc == this.pru.packUc &&
          item.packQty == this.pru.packQty &&
          item.objectId != this.itemObjectId
        ) {
          return true
        }

        return false
      })

      if (this.pru.packQty == 0 || this.pru.packQty == '') {
        this.alertValidatePRU = this.$i18n.t('validatepackQty')
      }
      if (this.pru.packUc < 0) {
        this.alertValidatePRU = this.$i18n.t('validatepackUc')
      }

      if (this.pru.packQty != 0 && this.pru.packUc >= 0) {
        if (newPRU.length == 0) {
          this.loadingButtonValidatePRU = true
          this.alertValidatePRU = ''
          this.saveProductpru()
        } else {
          this.alertValidatePRU = this.$i18n.t('validatePRU')
        }
      }
    },
  },
};
</script>
<style></style>
